window.injectMetaPixel = function() {
    const pixelId = import.meta.env.VITE_META_PIXEL_ID;
    
    if(pixelId){
        const metaScriptContent = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelId}');
            // fbq('track', 'PageView');
        `;
        
        const metaNoscriptContent = `
            <img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
        `;

        const endMetaPixelCode = `<!-- End Meta Pixel Code -->`;
        
        // Create script element
        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = metaScriptContent;

        // Create noscript element
        const noscriptElement = document.createElement('noscript');
        noscriptElement.innerHTML = metaNoscriptContent;

        

        // Append to head
        document.head.appendChild(scriptElement);
        document.head.appendChild(noscriptElement);
        document.head.insertAdjacentHTML('beforeend', endMetaPixelCode);

    } else {

        const endMetaPixelCode = `<!-- End Meta Pixel Code -->`;
        document.head.insertAdjacentHTML('beforeend', endMetaPixelCode);
        
    }
}